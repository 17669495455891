import React, { useState } from "react";
import useButtPlug from "./useButtPlug";

const DefaultClickToStart = ({ initiateConnection }) => (
  <div>
    {window.Bluetooth && false &&
      <button onClick={() => initiateConnection("bt")}>Connect Bluetooth</button>
    }
    <button onClick={() => initiateConnection("ws")}>Connect Intiface</button>
  </div>
);
const DefaultClickToStop = ({ stopConnecting }) => (
  <button onClick={stopConnecting}>Stop Connecting</button>
);
const DefaultConnected = () => <span>Connected!</span>;
const DefaultUnsupportedBrowser = () => (
  <span>Use Chrome to connect a toy.</span>
);

export default function ConnectAToy({
  clickToStart = DefaultClickToStart,
  clickToStop = DefaultClickToStop,
  connected = DefaultConnected,
  unsupportedBrowser = DefaultUnsupportedBrowser,
  onNewDevice,
  onDeviceDisconnected = null,
  ws = undefined,
}) {
  const [isReady, setReady] = useState(null);

  const bp = useButtPlug(isReady,
    // new device
    (device) => {
      console.log("new device", device);
      onNewDevice(device);
    },
    // device gone
    (device) => {
      if(onDeviceDisconnected) onDeviceDisconnected(device);
    },
    ws
  );

  if (bp.isConnected) return connected();
  if (!isReady) {
    return clickToStart({ initiateConnection: (t) => setReady(t) });
  } else {
    return clickToStop({ stopConnecting: () => setReady(null) });
  }
}
