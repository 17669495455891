import React from "react";
import { Share } from "./features/share/Share";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Share/>}>
      <Route path="/:group" element={<Share/>}></Route>
      </Route>
    </Routes>
  );
}

export default App;
