import { useState, useEffect, useRef } from "react";
import { ButtplugClient, ButtplugBrowserWebsocketClientConnector } from "buttplug";

export default function useButtPlug(ready, onNewDevice, onDeviceDisconnect = null, ws = "ws://127.0.0.1:12345") {
  const client = useRef(null);
  const [device, setDevice] = useState([]);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (client.current && !ready) {
      client.current.disconnect();
      client.current = null;
    }
    if (client.current || !ready) return;

    client.current = new ButtplugClient("Cammy");

    client.current.addListener("disconnect", () => {
      setIsConnected(false);
      client.current = null;
    });

    client.current.addListener("deviceadded", (device) => {
      setDevice(device);
      onNewDevice(device);
    });
    client.current.addListener("deviceremoved", (device) => {
      setDevice(null);
      if(onDeviceDisconnect) onDeviceDisconnect(device);
    });

    async function start() {
      try {
        let connector;
        if(ready == "bt") {
          //connector = new ButtplugEmbeddedConnectorOptions();
        } else {
          connector = new ButtplugBrowserWebsocketClientConnector(ws);
        }
        await client.current.connect(connector);
        setIsConnected(true);
      } catch (e) {
        console.log(e);
        return;
      }
      await client.current.startScanning();
    }
    start();
  }, [ready]);

  return { client, device, isConnected };
}
