import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectOthers, clientVibration, selectMe, clientSetAllow } from "./shareSlice";
import { Chip, Section, Text } from "../../layout";
import getClientId from "../../getClientId";

export default function Others() {
  const others = useSelector(selectOthers);
  const dispatch = useDispatch();
  return (
    <Fragment>
      {others.map((other, idx) => (
        <Other
          key={idx}
          other={other}
          setIntensity={(i) =>
            dispatch(clientVibration({ from: getClientId(), id: other.id, intensity: i }))
          }
        />
      ))}
    </Fragment>
  );
}

export function Other({ other, setIntensity }) {
  const { name, hasDevice, intensity, deviceName, allows, requirePermission } = other;
  const allowed = !requirePermission || !!allows[getClientId()];
  const me = useSelector(selectMe);
  const dispatch = useDispatch();

  return (
    <Chip>
      <Section padded>
        <div>
          <Text.Large>{name}</Text.Large>
          <Text.Medium style={{paddingLeft: "0.5em"}}>{deviceName}</Text.Medium>
        </div>
        { !allowed && <Text.Small>(Not Allowed)</Text.Small> }
        { me && me.requirePermission &&
          <label>
          <input
            defaultChecked={me.allows[other.id] === true} type="checkbox" onChange={(e) => {
            dispatch(clientSetAllow({
              id: me.id,
              other: other.id,
              value: e.target.checked
            }));
          }} />
          Allow
        </label>
        }
      </Section>
      <DeviceControl
        locked={!allowed}
        hasDevice={hasDevice}
        intensity={intensity}
        setIntensity={setIntensity}
      />
    </Chip>
  );
}

export function DeviceControl({ hasDevice, intensity, setIntensity, locked = false }) {
  if (!hasDevice) {
    return (
      <Section padded attention>
        <Text.Medium>No Device</Text.Medium>
      </Section>
    );
  }

  return (
    <Section padded attention>
      <input
        type="range"
        min="0"
        max="0.9"
        step="0.01"
        value={intensity}
        onChange={(e) => setIntensity(Number(e.target.value))}
        onFocus={() => console.log("onFocus")}
        onBlur={() => console.log("onBlur")}
        disabled={locked}
        style={{
          accentColor: `hsl(${intensity*360}deg, 100%, 50%)`
        }}
      />
    </Section>
  );
}
