import { configureStore } from "@reduxjs/toolkit";
import createSocketIoMiddleware from "redux-socket.io";
import io from "socket.io-client";
import shareReducer from "../features/share/shareSlice";
const SOCKET_PATH = "/api/socket.io/";

const socket = io("https://vibe.cbps.xyz/", {path: SOCKET_PATH});
let socketIoMiddleware = createSocketIoMiddleware(socket, "share/client");

export default configureStore({
  reducer: {
    share: shareReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(socketIoMiddleware),
});
