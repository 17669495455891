import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ConnectAToy, useVibration } from "react-buttplug";
import { Chip, Section, SectionCol, SectionButton, Text } from "../../layout";
import { DeviceControl } from "./Others";

import getClientId from "../../getClientId";
import { selectMe, join, clientDeviceState, clientRequirePermission, clientVibration } from "./shareSlice";

export default function Me() {
  const dispatch = useDispatch();
  const me = useSelector(selectMe);

  if (!me)
    return (
      <NewMe onJoin={(name) => dispatch(join({ id: getClientId(), name }))} />
    );

  return <ConnectedMe me={me} />;
}

function ConnectedMe({ me }) {
  const { id, name, intensity } = me;
  const dispatch = useDispatch();
  const device = useRef(null);

  const newDevice = useCallback((d) => {
    device.current = d;
    console.log(d);
    dispatch(clientDeviceState({ id, hasDevice: true, deviceName: d.name }));
  }, [id, dispatch]);

  const lostDevice = useCallback((d) => {
    device.current = null;
    dispatch(clientDeviceState({ id, hasDevice: false, deviceName: null }));
  }, [id, dispatch]);

  useVibration(device.current, intensity);

  return (
    <Chip>
      <Section padded>
        <SectionCol>
          <div>
            <Text.Large>{name}</Text.Large>
            <Text.Medium style={{paddingLeft: "0.5em"}}>{device.current?.name ?? ""}</Text.Medium>
          </div>
          <div>
          <label>
              <input type="checkbox" onChange={(e) => {
                dispatch(clientRequirePermission({ id, value: e.target.checked }));
              }} />
              Require Permission
            </label>
          </div>
        </SectionCol>
      </Section>
      <ConnectAToy
        onNewDevice={newDevice}
        onDeviceDisconnected={lostDevice}
        clickToStart={({ initiateConnection }) => (
          <Section>
            {window.Bluetooth && false && 
              <SectionButton onClick={() => initiateConnection("bt")}>
                Connect Bluetooth
              </SectionButton>
            }
            <SectionButton onClick={() => initiateConnection("ws")}>
              Connect Intiface
            </SectionButton>
          </Section>
        )}
        clickToStop={({ stopConnecting }) => (
          <Section>
            <SectionButton onClick={stopConnecting}>
              Stop Connecting
            </SectionButton>
          </Section>
        )}
        connected={() => (
          <Section attention padded>
            <SectionCol>
              <Text.Medium>Connected!</Text.Medium>
              <DeviceControl hasDevice={device.current != null} intensity={intensity} setIntensity={(i) =>
                dispatch(clientVibration({ from: me.id, id: me.id, intensity: i }))
              } />
            </SectionCol>
          </Section>
        )}
      />
    </Chip>
  );
}

function NewMe({ onJoin }) {
  const [name, setName] = useState("");

  const joinVibe = (e) => {
    onJoin(name);
    e.preventDefault();
  };

  return (
    <Chip>
      <Section padded>
        <form onSubmit={joinVibe}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <button type="submit">Join</button>
        </form>
      </Section>
    </Chip>
  );
}
